import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Subtitles,
    pubnub,
    TextToSpeech,
    SpeechToText,
    MainWrapper,
    useStateValue,
} from 'wwtc-sdk';
import {
    endpointSpeechToText,
    endpointTextToSpeech,
    endpointTranslate,
    signedEndpoint,
} from '../constants/connection';
import specialLanguages from '../constants/specialLanguages';
import { Download, Times, Users, User } from './svg/icons';
import buildSubtitlesFile from '../utils/subtitles';
import { handlePreRequestSubtitles } from './utils';

function Main() {
    // This values come from Login components
    // Also could set up constants values
    // This values are required like props of the MainWrapper
    const history = useHistory();
    const [modalParticipants, setModalParticipants] = useState(false);
    const [modalJoinRoom, setModalJoinRoom] = useState({
        visible: false,
        participantId: null,
        participantName: null,
    });

    const { userName, language, gender, roomName, apiToken, logoHeader, languagesPreTranslate } =
        history.location.state || {
            userName: '',
            language: '',
            gender: '',
            roomName: 'default', // default name
            apiToken: null,
            logoHeader: null,
            languagesPreTranslate: null,
        };

    useEffect(() => {
        if (userName === '') {
            history.push(`/`, {
                userName: userName,
                language: language,
                gender: gender,
            });
        }
        pubnub.setState({
            state: { role: 'moderator' },
            channels: [roomName],
        });

        pubnub.addListener({
            signal: function (s) {
                if (s.message.search('request_room') >= 0) {
                    const infoParsed = s.message.split('request_room-')[1];
                    const participantId = infoParsed.split('_')[0];
                    const participantName = infoParsed.split('_')[1];
                    setModalJoinRoom({ visible: true, id: participantId, name: participantName });
                }
            },
        });
    }, []);

    return (
        <MainWrapper
            autologin={false}
            gender={gender}
            language={language}
            roomName={roomName}
            userName={userName}
        >
            <Header
                logoHeader={logoHeader}
                pubnub={pubnub}
                roomName={roomName}
                setModalParticipants={setModalParticipants}
            />
            <WrapperComponents
                apiToken={apiToken}
                setModalParticipants={setModalParticipants}
                modalParticipants={modalParticipants}
                setModalJoinRoom={setModalJoinRoom}
                modalJoinRoom={modalJoinRoom}
                languagesPreTranslate={languagesPreTranslate}
            />
        </MainWrapper>
    );
}

function Header(props) {
    // initName comes from autologin component
    const { logoHeader, pubnub, initName, roomName, setModalParticipants } = props;

    const handleLogout = async () => {
        if (pubnub) {
            await pubnub.signal({
                message: 'logout_admin',
                channel: roomName,
            });
            pubnub.unsubscribeAll();
            window.location.replace(window.location.origin);
        } else {
            window.location.replace(window.location.origin);
        }
    };

    return (
        <header>
            <div className="header-left-section">
                <div className="info-user">
                    <img src={`${logoHeader}`} alt="WWTC" />
                </div>
            </div>
            <div className="header-center-section">
                <div className="direct-buttons hidden-mobile">
                    <span>The box 2.0</span>
                </div>
            </div>
            <div className="header-right-section">
                <div className="settings-user">
                    <span className="participants-label hidden-mobile">Roll Call</span>
                    &nbsp;
                    <span
                        className="participants-btn"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Participants"
                        data-container="body"
                        onClick={() => setModalParticipants(true)}
                    >
                        <Users />
                    </span>
                    &nbsp;&nbsp;
                    <span className="profile-name hidden-mobile">{initName}</span>
                    &nbsp;&nbsp;
                    <span
                        className="logout-btn"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Logout"
                        data-container="body"
                        onClick={handleLogout}
                    >
                        <i className="fas fa-sign-out-alt"></i>
                    </span>
                </div>
            </div>
        </header>
    );
}

function WrapperComponents(props) {
    // values from login
    const {
        apiToken,
        initName,
        initLanguage,
        initGender,
        conferenceName,
        modalParticipants,
        setModalParticipants,
        modalJoinRoom,
        setModalJoinRoom,
        languagesPreTranslate,
    } = props;
    const [isBuildingPdf, setIsBuildingPdf] = useState(false);
    const [modalDownload, setModalDownload] = useState(false);
    const [{ subtitles: subtitlesComponent }] = useStateValue();

    const isSpecialLanguage = (subtitles) => {
        const specialSubtitle = specialLanguages.find((special) => {
            var regex = new RegExp(special, 'g');
            var atLeastOneMatches = subtitles.some((e) => regex.test(e.speakerLanguage));
            return atLeastOneMatches;
        });

        return !!specialSubtitle;
    };

    const downloadSubtitlesPDF = (subtitles) => {
        setIsBuildingPdf(true);
        const languageBilingual = subtitlesComponent.sourceLanguage.value;
        const contentPDF = buildSubtitlesFile(subtitles, languageBilingual);

        // detect special languages
        const subtitlesWithSpecialLanguages = isSpecialLanguage(subtitles);

        // download unicode typography
        if (subtitlesWithSpecialLanguages) {
            window.pdfMake.fonts = {
                ArialUnicode: {
                    normal: 'https://sdkresources.s3.amazonaws.com/ArialUnicodeMSFont.ttf',
                    bold: 'https://sdkresources.s3.amazonaws.com/ArialUnicodeMSFont.ttf',
                    italics: 'https://sdkresources.s3.amazonaws.com/ArialUnicodeMSFont.ttf',
                },
            };
        }

        var docDefinition = {
            content: contentPDF,
            defaultStyle: {
                font: subtitlesWithSpecialLanguages ? 'ArialUnicode' : 'Roboto',
                fontSize: 12,
            },
        };

        window.pdfMake.createPdf(docDefinition).download(() => {
            setIsBuildingPdf(false);
        });
    };

    const fetchTextToSpeechCustom = async (endpoint, text, sourceLanguage, voice, itemTtsCall) => {
        try {
            let response = await fetch(endpoint.url.replace(/:sourceLanguage/g, sourceLanguage), {
                method: endpoint.method,
                redirect: 'follow',
                body: JSON.stringify({
                    text,
                }),
            });
            let data = await response.json();
            try {
                var arrayBuffer = _base64ToArrayBuffer(data.audio);
                let blob = new Blob([arrayBuffer], { type: 'audio/wav' });
                const downloadUrl = window.webkitURL.createObjectURL(blob);
                return { downloadUrl, indexCall: itemTtsCall };
            } catch (e) {
                return {
                    indexCall: itemTtsCall,
                    error: e,
                };
            }
        } catch (err) {
            return {
                indexCall: itemTtsCall,
                error: err,
            };
        }
    };

    const _base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    const kickOutParticipant = async (participant) => {
        await pubnub.signal({
            message: `kickout_participant-${participant.id}`,
            channel: conferenceName,
        });
    };

    const deleteParticipantsContent = async () => {
        await pubnub.signal({
            message: 'clean_history_content',
            channel: conferenceName,
        });
    };

    const letParticipantJoinRoom = async (participantId) => {
        await pubnub.signal({
            message: `join_room-${participantId}`,
            channel: conferenceName,
        });
    };

    return (
        <div className="wrapper-components">
            <Subtitles.SubtitlesUI
                username={initName}
                initialSourceLanguage={initLanguage}
                initialTargetLanguage={initLanguage}
                initialVisible={true}
                defaultBilingual={false}
                endpoint={signedEndpoint(apiToken, endpointTranslate)}
                provider="pubnub"
                speechRecognition={true}
                showButtonCloseComponent={false}
                showFlipButton={true}
                showSpeedOption={true}
                showLanguageServices={true}
                style={stylesWrapper.fullContainer}
                ttsOption={true}
                channel={conferenceName}
                languagesPreTranslate={languagesPreTranslate}
                render={(subtitles) =>
                    isBuildingPdf ? null : (
                        <button
                            className="a-element"
                            onClick={() => downloadSubtitlesPDF(subtitles)}
                        >
                            <Download />
                        </button>
                    )
                }
                preRequestSubtitles={handlePreRequestSubtitles}
            />
            <SpeechToText.SpeechToTextUI
                username={initName}
                initialLanguage={initLanguage}
                initialVisible={false}
                initialActive={false}
                withUI={false} // to use only speech recognition
                endpoint={signedEndpoint(apiToken, endpointSpeechToText)}
            />
            <TextToSpeech.TextToSpeechUI
                initialLanguage={initLanguage}
                initialGender={initGender}
                initialVisible={false}
                initialActive={false}
                withUI={false} // to use only audio player
                endpoint={signedEndpoint(apiToken, endpointTextToSpeech)}
                fetchCustom={fetchTextToSpeechCustom}
            />
            {modalParticipants ? (
                <ModalParticipants
                    closeModal={() => setModalParticipants(false)}
                    pubnub={pubnub}
                    conferenceName={conferenceName}
                    kickOutParticipant={kickOutParticipant}
                    deleteParticipantsContent={deleteParticipantsContent}
                />
            ) : null}
            {modalJoinRoom.visible ? (
                <ModalJoinRoom
                    closeModal={() => setModalJoinRoom({ ...modalJoinRoom, visible: false })}
                    pubnub={pubnub}
                    conferenceName={conferenceName}
                    letParticipantJoinRoom={letParticipantJoinRoom}
                    modalJoinRoom={modalJoinRoom}
                />
            ) : null}
            {isBuildingPdf ? <ModalDownload onCloseModal={() => setModalDownload(false)} /> : null}
        </div>
    );
}

const ModalDownload = ({ onCloseModal }) => {
    return (
        <div style={stylesWrapper.containerModal}>
            <div style={{ ...stylesWrapper.wrapperModal, height: 180, width: 450 }}>
                <div className="header-container">
                    <div className="header-title">
                        <span>PDF Subtitles</span>
                    </div>
                    <div className="header-options"></div>
                </div>
                <div
                    className="body-container"
                    style={{ ...stylesWrapper.bodyModal, textAlign: 'center' }}
                >
                    <p>Downloading PDF file...</p>
                    <p style={{ fontSize: 14 }}>Please wait, this could take a few seconds.</p>
                </div>
            </div>
        </div>
    );
};

const ModalParticipants = (props) => {
    const { closeModal, pubnub, conferenceName, kickOutParticipant, deleteParticipantsContent } =
        props;
    const [participants, setParticipants] = useState([]);
    const myPubnubId = pubnub.userId;

    useEffect(() => {
        pubnub
            .hereNow({
                channels: [conferenceName],
                includeState: true,
            })
            .then(async (res) => {
                let participants = [];
                const participantsInChannel = res.channels[conferenceName].occupants;

                for (let index = 0; index < participantsInChannel.length; index++) {
                    const item = participantsInChannel[index];

                    try {
                        const { data } = await pubnub.objects.getUUIDMetadata({
                            uuid: item.uuid,
                        });
                        participants.push(data);
                    } catch (e) {
                        console.log(e);
                    }
                }
                setParticipants(participants);
            });
    }, []);

    const handleParticipantKickOut = (participant) => {
        kickOutParticipant(participant);
        const filteredParticipants = participants.filter((e) => e.id !== participant.id);

        setParticipants(filteredParticipants);
    };

    const downloadParticipants = () => {
        var contentPDF = [];
        contentPDF.push({
            text: `${conferenceName} - Roll Call`,
            style: 'header',
            bold: true,
        });
        participants.forEach((item, index) => {
            contentPDF.push({
                ul: [{ text: item.name, bold: false }],
            });
        });

        var docDefinition = {
            content: contentPDF,
            defaultStyle: {
                fontSize: 12,
            },
        };
        window.pdfMake.createPdf(docDefinition).download('rollCallWWTC.pdf');
    };

    return (
        <div style={stylesWrapper.containerModal}>
            <div style={stylesWrapper.wrapperModal}>
                <div className="header-container">
                    <div className="header-title">
                        <span>Roll Call</span>
                    </div>
                    <div className="header-options">
                        <button className="a-element" onClick={downloadParticipants}>
                            <Download />
                        </button>
                        <button className="a-element" onClick={closeModal}>
                            <Times />
                        </button>
                    </div>
                </div>
                <div className="body-container" style={stylesWrapper.bodyModal}>
                    {participants.map((item, index) => {
                        return (
                            <div
                                key={index}
                                style={stylesWrapper.participantItem}
                                className="participant-item"
                            >
                                <div style={stylesWrapper.descriptionParticipant}>
                                    <span>
                                        <User />
                                    </span>
                                    &nbsp;&nbsp;
                                    <p style={{ margin: 0, textIndent: 0 }}>
                                        {item.name}
                                        {myPubnubId === item.id ? ' (You)' : null}
                                    </p>
                                </div>
                                <div>
                                    {myPubnubId !== item.id ? (
                                        <button
                                            className="a-element"
                                            onClick={() => handleParticipantKickOut(item)}
                                        >
                                            <Times />
                                        </button>
                                    ) : null}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <hr />
                <div
                    className="footer-container d-flex justify-content-center align-items-center"
                    styles={stylesWrapper.footerModal}
                >
                    <button
                        className=" btn btn-success btn-sm"
                        disabled={participants.length === 1}
                        onClick={deleteParticipantsContent}
                    >
                        Delete participants content
                    </button>
                </div>
            </div>
        </div>
    );
};

const ModalJoinRoom = (props) => {
    const { closeModal, modalJoinRoom, letParticipantJoinRoom } = props;
    const { id, name } = modalJoinRoom;

    const handleJoinRoom = () => {
        letParticipantJoinRoom(id);
        closeModal();
    };

    return (
        <div style={stylesWrapper.containerModal}>
            <div style={{ ...stylesWrapper.wrapperModal, height: 200 }}>
                <div className="header-container">
                    <div className="header-title">
                        <span>Join participant</span>
                    </div>
                    <div className="header-options">
                        <button className="a-element" onClick={closeModal}>
                            <Times />
                        </button>
                    </div>
                </div>
                <div className="body-container text-center" style={stylesWrapper.bodyModal}>
                    <p>
                        {' '}
                        <strong>{name}</strong> would like to join in the room
                    </p>
                </div>
                <div
                    className="footer-container d-flex justify-content-center align-items-center"
                    styles={stylesWrapper.footerModal}
                >
                    <button className="btn btn-primary" onClick={handleJoinRoom}>
                        let in room
                    </button>
                </div>
            </div>
        </div>
    );
};

const stylesWrapper = {
    fullSubtitlesContainer: {
        width: 'calc(100% - 10px)',
        height: 'calc(100% - 10px)',
        margin: '5px auto',
        position: 'relative',
        zIndex: 20,
    },
    subtitlesContainer: {
        width: 'calc(100% - 10px)',
        height: 'calc(50% - 10px)',
        margin: '5px auto',
    },
    chatContainer: {
        width: 'calc(100% - 10px)',
        height: 'calc(50% - 10px)',
        margin: '5px auto',
    },
    containerModal: {
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100vh - 50px)',
        width: '100vw',
        background: 'rgba(1,1,1,0.3)',
        position: 'absolute',
        left: 0,
        top: 0,
        zIndex: 2000,
    },
    bodyModal: {
        marginTop: 24,
        height: 'calc(100% - 90px)',
        overflow: 'auto',
    },
    footerModal: {},
    wrapperModal: {
        padding: '10px',
        backgroundColor: 'white',
        borderRadius: 5,
        height: 500,
        top: 'calc(50% - 250px)',
        width: 320,
        position: 'absolute',
        WebkitBoxShadow: '4px 4px 9px -1px rgba(0,0,0,0.75)',
        MozBoxShadow: '4px 4px 9px -1px rgba(0,0,0,0.75)',
        boxShadow: '4px 4px 9px -1px rgba(0,0,0,0.75)',
    },
    participantItem: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '12px 1em',
        cursor: 'default',
    },
    descriptionParticipant: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    fullContainer: {
        width: 'calc(100% - 20px)',
        height: '100%',
        margin: '10px auto',
    },
};

export default Main;
